@import 'styles/variables.scss';

.video-js {
  border-radius: 10px;

  .vjs-tech {
    border-radius: 10px;
  }

  .vjs-big-play-button {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 0;
    background-color: $black-07;

    .vjs-icon-placeholder {
      &::before {
        content: url('../../images/newIcons/play.svg') !important;
        width: 22px;
        height: 24px;
        left: 17px;
        top: 7px;
      }
    }

    &:hover {
      background-color: $black-07 !important;
    }
  }

  .vjs-control-bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: $dark-color;
    opacity: 0.8;
    height: 4em;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

    .vjs-play-control {
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 26px;
      width: 16px;
      height: 16px;

      &.vjs-playing .vjs-icon-placeholder:before {
        content: '';
        background-image: url('../../images/newIcons/pause.svg');
        width: 16px;
        height: 16px;
        background-size: cover;
        left: 0;
        top: 0;
      }

      &.vjs-paused .vjs-icon-placeholder:before {
        content: '';
        background-image: url('../../images/newIcons/play.svg');
        width: 16px;
        height: 16px;
        background-size: cover;
        left: 0;
        top: 0;
      }

      .vjs-icon-placeholder:before {
        content: '';
        background-image: url('../../images/newIcons/play.svg');
        width: 16px;
        height: 16px;
        background-size: cover;
        left: 0;
        top: 0;
      }
    }
  }

  .vjs-mute-control {
    width: 20px;
  }

  .vjs-volume-panel {
    width: 25px;
    margin-left: 14px;

    &.vjs-volume-panel-horizontal {
      &:active,
      &:focus,
      &:hover {
        width: 70px;
        transition: width 0.1s;
      }
    }

    .vjs-volume-bar {
      margin: 19px 4px;
    }
  }

  .vjs-play-progress {
    background-color: $bright-product-color;
    border-radius: 10px;

    &:before {
      font-size: 16px;
      line-height: 8px;
    }
  }

  .vjs-progress-holder {
    height: 8px;
    border-radius: 10px;

    .vjs-load-progress {
      border-radius: 10px;

      div {
        border-radius: 10px;
      }
    }
  }

  .vjs-time-control {
    line-height: 4em;
  }

  .vjs-button > .vjs-icon-placeholder:before {
    line-height: 40px;
  }
}

.vjs-fluid:not(.vjs-audio-only-mode) {
  padding-top: 38% !important;
}
