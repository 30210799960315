@mixin box-shadow-1 {
  box-shadow: 0 0 1px 2px rgba(51, 51, 51, 0.3);
  -webkit-box-shadow: 0 0 1px 2px rgba(51, 51, 51, 0.3);
  -moz-box-shadow: 0 0 1px 2px rgba(51, 51, 51, 0.3);
}

@mixin gradient-1 {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
}

@mixin gradient-2 {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
}

@mixin flex($direction: false, $content: false, $items: false) {
  display: flex;

  @if $direction {
    flex-direction: $direction;
  }

  @if $content {
    justify-content: $content;
  } @else {
    justify-content: flex-start;
  }

  @if $items {
    align-items: $items;
  } @else {
    align-items: center;
  }
}

@mixin font($size, $weight, $height, $spacing) {
  @if $size {
    font-size: $size;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $height {
    line-height: $height;
  }
  @if $spacing {
    letter-spacing: $spacing;
  }
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}

@mixin element-color($color) {
  background-image: url('data:image/svg+xml;utf8,<svg ...><g stroke="#{$color}" ... /></g></svg>');
}
