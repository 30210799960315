.text {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  transition: max-height 0.5s ease-in-out;
  max-height: 43px;
}

.show {
  -webkit-line-clamp: unset;
  max-height: 100vh;
}
