.tpg-title-one {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
}

.tpg-title-four {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0em;
}

.tpg-title-two {
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0em;
}

.tpg-title-three {
  //styleName: Noto 14B;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
}

.tpg-title-five {
  font-size: 32px;
  font-weight: 500;
  line-height: 32px;
}

.tpg-text-one {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
}

.tpg-text-two {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
}

.tpg-text-three {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

.tpg-text-four {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0em;
}

.tpg-text-five {
  font-size: 18px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: 0em;
}

.tpg-text-six {
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
  letter-spacing: 0em;
}

.tpg-button-one {
  //styleName: Button/16SB;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
}

.tpg-body-one {
  //styleName: Body/14M;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  background: none;
  border: none;
  margin-top: 2px;
  margin-bottom: 2px;
  cursor: pointer;
}

.tpg-alert {
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
}

// ^^^ Deprecated typography

// Redesign-era typography

.tpg-h1 {
  //styleName: H1;
  font-size: 36px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0;
}

.tpg-h2 {
  //styleName: H2;
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 3.84px;
}

.tpg-h3 {
  //styleName: H3;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 2.64px;
}

.tpg-h4 {
  //styleName: H4;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.16px;
}

.tpg-b1 {
  //styleName: body 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.16px;
}

.tpg-b2 {
  //styleName: body 2;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
}

.tpg-input {
  //styleName: input;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.16px;
}

.tpg-c1 {
  //styleName: caption;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.28px;
}

.tpg-c2 {
  //styleName: caption 2;
  font-size: 11px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.88px;
  text-transform: uppercase;
}
