@import 'styles/mixins.scss';
@import 'styles/variables.scss';

.input {
  &__label {
    margin-bottom: 8px;
    color: $base-text-color;
    display: inline-block;

    &-readOnly {
      opacity: 0.5;
    }
  }

  &__search-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__calendar-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 8px;
    transform: translateY(-50%);
    top: 19px;
  }

  &__text {
    padding: 0 16px;
    &:focus {
      border: none;
      outline: none;
    }

    @include placeholder {
      color: $base-text-color;
    }
  }

  &__search {
    &-s {
      padding-left: 26px;
    }
    &-m {
      padding-left: 32px;
    }
    &-l {
      padding-left: 40px;
    }
  }

  &__children {
    &-s {
      position: absolute;
      right: 0;
      top: 0;
      margin-top: 2px;
      margin-right: 6px;
      cursor: pointer;
      svg {
        width: 12px;
        height: 15px;
      }
    }
    &-m {
      position: absolute;
      right: 0;
      top: 0;
      margin-top: 8px;
      margin-right: 12px;
      cursor: pointer;
      svg {
        width: 15px;
        height: 15px;
      }
    }
    &-l {
      position: absolute;
      right: 0;
      top: 0;
      margin-top: 10px;
      margin-right: 16px;
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  &__reset,
  &__password {
    width: 16px;
    height: 16px;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    @include flex(false, center, center);
  }

  &__password svg {
    width: 16px;
    height: 16px;
  }

  .error-container {
    margin: 4px 0 0 12px;
    color: $red-color-3;
  }
}
