// colors

$white-color: #fff;
$black-color: #000;
$base-color: #333333;
$black-04: rgba(0, 0, 0, 0.4);
$black-05: rgba(0, 0, 0, 0.5);
$black-07: rgba(0, 0, 0, 0.7);

$blue-color: #2f80ed;
$blue-02: #8dbdf533;
$blue-03: #e8f2fd;
$turquoise: #8dbdf5;
$turquoise-07: rgba(141, 189, 245, 0.7);
$bg-color: #f4f4f4;
$bg-dark-color: #cecece;
$dark-blue-color: #494949;
$grey-color: #d0d0d0;
$green-color: #8dc9a6;
$dark-green-color: #6fcf97;
$yellow-color: #f5eb8d;
$dark-yellow-color: #2b2b29;
$grey-04: #bdbdbd;
$grey-03: #828282;
$red-color: #eb5757;
$red-color-2: #c64848;
$red-color-2-02: rgba(198, 72, 72, 0.2);
$red-color-3: #eb3333;

$pale-grey-color: #f2f2f2;
$pink-color: #e0e0e0;

$light-grey: #4f4f4f;
$light-red-color: #727070;
$control-default: #002033;
$light-blue-color: rgba(73, 73, 73, 0.5);
$light-blue-02: rgba(141, 189, 245, 0.2);
$light-grey-05: rgba(189, 189, 189, 0.5);
$light-grey-02: rgba(245, 235, 141, 0.2);
$dark-blue-004: rgba(0, 32, 51, 0.04);
$dark-blue-012: rgba(0, 32, 51, 0.12);

$orange: #f5bf8d;
$orange-02: rgba(245, 191, 141, 0.2);

$silver: #d5d5d5;
$light-silver: #e8e8e8;

$dark-theme-background-color: #181a25;
$dark-theme-surface-bg-color-2: rgba(27, 29, 42, 0.94);
$dark-theme-surface-bg-color-3: rgba(46, 50, 79, 0.94);
$dark-theme-surface-bg-color-4: #3f3f3f;
$dark-theme-text-color: #faf0e6;

// NOTE!!!: next color variables are duplicated in tailwind.config.js
// Brand new redesign colors
// Prod colors
$dark-product-color: #053c92;
$main-product-color: #1d65d4;
$bright-product-color: #4282e6;

// Black colors
$ultrablack-color: #0a0c17;
$dark-color: #171925;
$light-color: #1d1f2d;
$bright-color: #20242e;

// Text colors
$title-color: #e3e6f1;
$base-text-color: #9599a6;
$light-text-color: #4d5064;

// Success/Error colors
$success-color: #42bc1b;
$error-color: #ea3737;
